











































































































import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";

import SidebarForm from "@/accounting-portal/components/SidebarForm.vue";
import { VSelect, VCombobox } from "vuetify/lib";

import {
  ApplicationConfigKeyModel,
  ApplicationConfigBooleanKeyModel,
  ApplicationConfigDateKeyModel,
  ApplicationConfigNumberKeyModel,
  ApplicationConfigStringKeyModel,
  ApplicationConfigNumbersKeyModel,
  ApplicationConfigStringsKeyModel,
} from "@/application-manager/models/ApplicationConfigKeyModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import {
  ApplicationConfigKeyType,
  ApplicationFeatureMissedKey,
} from "@/application-manager/models/ApplicationFeatureModel";

@Component({ components: { SidebarForm, VSelect, VCombobox } })
export default class ApplicationConfigKeysForm extends Vue {
  @Prop() missedKeys!: Array<ApplicationFeatureMissedKey>;

  @Inject() applicationFeatureControl!: {
    updateMissedKeys: (value: Array<ApplicationFeatureMissedKey>) => void;
  };

  readonly ApplicationConfigKeyType = ApplicationConfigKeyType;
  readonly ApplicationConfigStringsKeyModel = ApplicationConfigStringsKeyModel;
  readonly REQUIRED_RULE = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly REQUIRED_ARRAY_RULE = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];
  readonly REQUIRED_NUMBER_RULE = [
    ValidUtil.requiredNumber(this.$lang("validation.required")),
  ];

  keys: Array<ApplicationConfigKeyModel> = [];
  menus: Array<boolean> = [];

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get visible(): boolean {
    return !!this.missedKeys.length;
  }

  set visible(visible: boolean) {
    if (visible) {
      return;
    }

    this.applicationFeatureControl.updateMissedKeys([]);
  }

  get isSaving(): boolean {
    return this.$store.state.applicationInfoStore.isApplicationConfigKeysSaving;
  }

  @Watch("missedKeys", { immediate: true })
  watchMissedKeys(missedKeys: Array<ApplicationFeatureMissedKey>) {
    const typeSortOrder = [
      ApplicationConfigKeyType.DATE,
      ApplicationConfigKeyType.STRING_ARRAY,
      ApplicationConfigKeyType.NUMBER_ARRAY,
      ApplicationConfigKeyType.STRING,
      ApplicationConfigKeyType.NUMBER,
      ApplicationConfigKeyType.BOOLEAN,
    ];

    this.keys = [...missedKeys]
      .sort(
        (
          { type: typeA }: ApplicationFeatureMissedKey,
          { type: typeB }: ApplicationFeatureMissedKey
        ) => typeSortOrder.indexOf(typeA) - typeSortOrder.indexOf(typeB)
      )
      .map(({ key, type, values }: ApplicationFeatureMissedKey) =>
        ({
          [ApplicationConfigKeyType.BOOLEAN]: () =>
            new ApplicationConfigBooleanKeyModel(key, type),
          [ApplicationConfigKeyType.DATE]: () =>
            new ApplicationConfigDateKeyModel(key, type),
          [ApplicationConfigKeyType.NUMBER]: () =>
            new ApplicationConfigNumberKeyModel(key, type),
          [ApplicationConfigKeyType.NUMBER_ARRAY]: () =>
            new ApplicationConfigNumbersKeyModel(key, type),
          [ApplicationConfigKeyType.STRING]: () =>
            new ApplicationConfigStringKeyModel(key, type),
          [ApplicationConfigKeyType.STRING_ARRAY]: () =>
            new ApplicationConfigStringsKeyModel(key, type, values),
        }[type]())
      );
    this.menus = this.keys.map(() => false);
  }

  async addApplicationConfigKeys() {
    await this.$store.dispatch("addApplicationConfigKeys", {
      appId: this.applicationId,
      properties: this.keys.map(({ request }) => request),
    });
    this.visible = false;
  }
}
