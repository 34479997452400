import { ApplicationConfigKeyType } from "./ApplicationFeatureModel";

export interface ApplicationConfigUpdateRequest {
  appId: string;
  properties: Array<ApplicationConfigKeyRequest>;
}

export interface ApplicationConfigKeyRequest {
  key: string;
  values: Array<string>;
}

export abstract class ApplicationConfigKeyModel {
  abstract value?: string | number | Array<string> | Array<number> | boolean;

  constructor(public key: string, public type: ApplicationConfigKeyType) {}

  abstract get request(): ApplicationConfigKeyRequest;
}

export class ApplicationConfigNumberKeyModel extends ApplicationConfigKeyModel {
  value?: number;

  constructor(key: string, type: ApplicationConfigKeyType) {
    super(key, type);
  }

  get request(): ApplicationConfigKeyRequest {
    return {
      key: this.key,
      values: [this.value?.toString() ?? ""],
    };
  }
}

export class ApplicationConfigStringKeyModel extends ApplicationConfigKeyModel {
  value?: string;

  constructor(key: string, type: ApplicationConfigKeyType) {
    super(key, type);
  }

  get request(): ApplicationConfigKeyRequest {
    return {
      key: this.key,
      values: [this.value ?? ""],
    };
  }
}

export class ApplicationConfigDateKeyModel extends ApplicationConfigKeyModel {
  value?: string;

  constructor(key: string, type: ApplicationConfigKeyType) {
    super(key, type);
  }

  get request(): ApplicationConfigKeyRequest {
    return {
      key: this.key,
      values: [this.value ?? ""],
    };
  }
}

export class ApplicationConfigBooleanKeyModel extends ApplicationConfigKeyModel {
  value?: boolean;

  constructor(key: string, type: ApplicationConfigKeyType) {
    super(key, type);
  }

  get request(): ApplicationConfigKeyRequest {
    return {
      key: this.key,
      values: [this.value?.toString() ?? ""],
    };
  }
}

export class ApplicationConfigNumbersKeyModel extends ApplicationConfigKeyModel {
  constructor(
    key: string,
    type: ApplicationConfigKeyType,
    public value: Array<number> = []
  ) {
    super(key, type);
  }

  get request(): ApplicationConfigKeyRequest {
    return {
      key: this.key,
      values: this.value.map((number) => number.toString()),
    };
  }
}

export class ApplicationConfigStringsKeyModel extends ApplicationConfigKeyModel {
  constructor(
    key: string,
    type: ApplicationConfigKeyType,
    public items: Array<string> = [],
    public value: Array<string> = []
  ) {
    super(key, type);
  }

  get request(): ApplicationConfigKeyRequest {
    return {
      key: this.key,
      values: this.value,
    };
  }
}
