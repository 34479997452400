

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ApplicationFeaturesPreviews extends Vue {
  @Prop() addedFeatures!: Array<string>;
  @Prop() deletedFeatures!: Array<string>;

  get visible(): boolean {
    return !!(this.addedFeatures.length || this.deletedFeatures.length);
  }
}
