














import { Component, Prop, Vue } from "vue-property-decorator";

import ApplicationFeaturesTreeItem from "@/application-manager/components/ApplicationFeaturesTreeItem.vue";

import { ApplicationFeatureModel } from "@/application-manager/models/ApplicationFeatureModel";

@Component({ components: { ApplicationFeaturesTreeItem } })
export default class ApplicationFeaturesTree extends Vue {
  @Prop() value!: Array<string>;
  @Prop() items!: Array<ApplicationFeatureModel>;
  @Prop() isEditing!: boolean;
  @Prop() includedFeatures?: Array<string>;
}
