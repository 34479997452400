






















































import { intersection } from "lodash";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";

import {
  ApplicationFeatureModel,
  ApplicationFeatureMissedKey,
} from "@/application-manager/models/ApplicationFeatureModel";

@Component({
  name: "ApplicationFeaturesTreeItem",
  components: { ApplicationFeaturesTreeItem },
})
export default class ApplicationFeaturesTreeItem extends Vue {
  @Prop() value!: Array<string>;
  @Prop() item!: ApplicationFeatureModel;
  @Prop() isEditing!: boolean;
  @Prop() includedFeatures?: Array<string>;
  @Prop() isRoot?: boolean;

  @Inject() applicationFeatureControl!: {
    updateMissedKeys: (value: Array<ApplicationFeatureMissedKey>) => void;
    updateIncludedFeatures: (
      feature: ApplicationFeatureModel,
      value: boolean
    ) => void;
    updateExcludedFeatures: (
      feature: ApplicationFeatureModel,
      value: boolean
    ) => void;
  };

  menuVisible = false;

  get checked(): boolean {
    return this.value.includes(this.item.feature);
  }

  set checked(newValue: boolean) {
    if (this.includedFeatures) {
      this.applicationFeatureControl.updateExcludedFeatures(
        this.item,
        newValue
      );
    } else {
      this.applicationFeatureControl.updateIncludedFeatures(
        this.item,
        newValue
      );
    }
  }

  get hasCheckedChildren(): boolean {
    return !!intersection(this.value, this.item.deepChildren).length;
  }

  get itemVisible(): boolean {
    if (
      this.includedFeatures &&
      ((this.includedFeatures.includes(this.item.feature) &&
        !this.item.children.length) ||
        (this.item.deepChildren.length &&
          this.item.deepChildren.every((feature) =>
            this.includedFeatures?.includes(feature)
          )))
    ) {
      return false;
    }

    return (
      !this.includedFeatures ||
      this.isEditing ||
      this.checked ||
      this.hasCheckedChildren
    );
  }

  get hasMissedKeys(): boolean {
    return !!this.item.missedKeys.length;
  }

  get disabled(): boolean {
    return (
      this.item.disabled ||
      this.hasMissedKeys ||
      !this.isEditing ||
      (this.includedFeatures && this.isRoot) ||
      !!(
        this.includedFeatures &&
        this.includedFeatures.includes(this.item.feature) &&
        this.item.children.length
      )
    );
  }

  get buttonVisible(): boolean {
    return (
      this.hasMissedKeys &&
      this.isEditing &&
      !(this.isRoot && this.includedFeatures)
    );
  }

  created() {
    if (this.hasCheckedChildren) {
      this.menuVisible = true;
    }
  }
}
