




























































































import { Component, Provide, Vue, Watch } from "vue-property-decorator";
import { difference, remove, xor } from "lodash";

import ApplicationFeaturesPreviews from "@/application-manager/components/ApplicationFeaturesPreviews.vue";
import ApplicationFeaturesTree from "@/application-manager/components/ApplicationFeaturesTree.vue";
import ApplicationConfigKeysForm from "@/application-manager/components/ApplicationConfigKeysForm.vue";

import {
  ApplicationFeatureMissedKey,
  ApplicationFeatureModel,
  ApplicationFeaturesResponse,
} from "@/application-manager/models/ApplicationFeatureModel";

@Component({
  components: {
    ApplicationFeaturesPreviews,
    ApplicationFeaturesTree,
    ApplicationConfigKeysForm,
  },
})
export default class ApplicationsFeaturesControlView extends Vue {
  includedFeatures: Array<string> = [];
  excludedFeatures: Array<string> = [];
  isEditing = false;
  missedKeys: Array<ApplicationFeatureMissedKey> = [];

  get features(): Array<ApplicationFeatureModel> {
    return this.$store.state.applicationInfoStore.applicationFeatures;
  }

  get currentApplicationFeatures(): ApplicationFeaturesResponse | null {
    return this.$store.state.applicationInfoStore.currentApplicationFeatures;
  }

  get appName(): string {
    return this.$store.state.application.appName;
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get addedIncludedFeatures(): Array<string> {
    return this.currentApplicationFeatures
      ? difference(
          this.includedFeatures,
          this.currentApplicationFeatures.featuresIncluded
        )
      : [];
  }

  get deletedIncludedFeatures(): Array<string> {
    return this.currentApplicationFeatures
      ? difference(
          this.currentApplicationFeatures.featuresIncluded,
          this.includedFeatures
        )
      : [];
  }

  get addedExcludedFeatures(): Array<string> {
    return this.currentApplicationFeatures
      ? difference(
          this.excludedFeatures,
          this.currentApplicationFeatures.featuresExcluded
        )
      : [];
  }

  get deletedExcludedFeatures(): Array<string> {
    return this.currentApplicationFeatures
      ? difference(
          this.currentApplicationFeatures.featuresExcluded,
          this.excludedFeatures
        )
      : [];
  }

  get saveable(): boolean {
    if (!this.currentApplicationFeatures) {
      return false;
    }

    return !!(
      xor(
        this.currentApplicationFeatures.featuresIncluded,
        this.includedFeatures
      ).length ||
      xor(
        this.currentApplicationFeatures.featuresExcluded,
        this.excludedFeatures
      ).length
    );
  }

  get isLoading(): boolean {
    return (
      this.$store.state.applicationInfoStore
        .isCurrentApplicationFeaturesLoading ||
      this.$store.state.applicationInfoStore.isApplicationFeaturesLoading
    );
  }

  get isSaving(): boolean {
    return this.$store.state.applicationInfoStore
      .isCurrentApplicationFeaturesSaving;
  }

  @Watch("currentApplicationFeatures", { immediate: true })
  setInitialFeatures() {
    if (!this.currentApplicationFeatures) {
      return;
    }

    this.includedFeatures = [
      ...this.currentApplicationFeatures.featuresIncluded,
    ];
    this.excludedFeatures = [
      ...this.currentApplicationFeatures.featuresExcluded,
    ];
  }

  @Watch("applicationId", { immediate: true })
  watchApp() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang(
        "applicationManager.applicationFeaturesControl.title",
        this.appName
      )
    );

    this.isEditing = false;
    this.$store.dispatch("getCurrentApplicationFeatures", this.applicationId);
    this.$store.dispatch("fetchApplicationFeatures", this.applicationId);
  }

  async saveFeatures() {
    await this.$store.dispatch("updateCurrentApplicationFeatures", {
      appId: this.applicationId,
      featuresIncluded: this.includedFeatures,
      featuresExcluded: this.excludedFeatures,
    });
    this.isEditing = false;
  }

  cancelEditing() {
    this.isEditing = false;
    this.setInitialFeatures();
  }

  updateIncludedFeatures(
    updatedFeature: ApplicationFeatureModel,
    value: boolean
  ) {
    if (!value) {
      this.includedFeatures.splice(
        this.includedFeatures.findIndex(
          (feature) => feature === updatedFeature.feature
        ),
        1
      );

      return;
    }

    this.includedFeatures.push(updatedFeature.feature);
    remove(this.includedFeatures, (feature) =>
      updatedFeature.deepChildren.includes(feature)
    );

    if (!this.excludedFeatures.includes(updatedFeature.feature)) {
      return;
    }

    this.excludedFeatures.splice(
      this.excludedFeatures.findIndex(
        (feature) => feature === updatedFeature.feature
      ),
      1
    );
  }

  updateExcludedFeatures(
    updatedFeature: ApplicationFeatureModel,
    value: boolean
  ) {
    if (value) {
      this.excludedFeatures.push(updatedFeature.feature);

      return;
    }

    this.excludedFeatures.splice(
      this.excludedFeatures.findIndex(
        (feature) => feature === updatedFeature.feature
      ),
      1
    );
  }

  updateMissedKeys(keys: Array<ApplicationFeatureMissedKey>) {
    this.missedKeys = keys;
  }

  @Provide() applicationFeatureControl = {
    updateMissedKeys: this.updateMissedKeys,
    updateIncludedFeatures: this.updateIncludedFeatures,
    updateExcludedFeatures: this.updateExcludedFeatures,
  };
}
